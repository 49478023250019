import React from "react"
import { Helmet } from 'react-helmet'
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import PageHeading from "../components/PageHeading"
import PromoVideo from "../components/PromoVideo"
import ImageText from "../components/ImageText"
import "../assets/sass/style.scss"

const About = ({ props }) => {
  return (
    <Layout>
      <SEO
        title="About Laura - Former Couch Potato"
        description="Today I enjoy teaching fitness to people who want to feel fitter and stronger without it feeling like too much of a chore."
      />

      <Helmet>
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "Person",
            "email": "mailto:laura@laurawilliamsonline.co.uk",
            "image": "https://laurawilliamsonline.co.uk/static/e6b1d7fce9118fcbf5cb6dd1349ef37b/c252f/Laura_Williams_London%402x.jpg",
            "jobTitle": "Fitness Expert",
            "name": "Laura Williams ",
            "url": "https://laurawilliamsonline.co.uk"
          }
        `}
        </script>
      </Helmet>

      <PageHeading text="About">
        <p>
          Laura Williams is a former couch potato who revamped her life to
          become one of the UK's leading fitness experts.
        </p>
      </PageHeading>

      <ImageText
        className="bg-red big-quotes"
        heading="Not from a fitness background"
        imageSrc="Laura_Williams@2x.jpg"
        imageAlt="Laura Williams"
      >
        <p>
          “<span className="quote-mark quote-mark--open">I</span> hated PE at
          school. I felt self-conscious and like I wasn’t made for sport.
        </p>
        <p>
          I started exercising when I stopped smoking in my twenties and quickly
          became hooked on the physical and mental benefits of exercise. The
          trick is finding what works for{" "}
          <span className="quote-mark quote-mark--close">you.</span>”
        </p>
      </ImageText>

      <ImageText
        className="image-text--reverse bg-grey big-quotes"
        heading="Fitter and happier"
        imageSrc="Laura_Williams_London@2x.jpg"
        imageAlt="Personal Training photograph"
      >
        <p>
          “Today I enjoy teaching fitness to people who want to feel fitter and
          stronger without it feeling like too much of a chore.
        </p>
        <p>I think even the toughest of sessions should be enjoyable.</p>
        <p>
          And everyone has something they enjoy: whether that’s the discipline
          of discovering long-forgotten abs; the mind-clearing that comes from a
          good sweat, or the smug feeling of accomplishment when you’ve stuck to
          the plan.”
        </p>
      </ImageText>

      <PromoVideo
        cssClass="promo-video bg-yellow"
        heading="What I offer"
        videoSrcURL="https://player.vimeo.com/video/508850105?loop=1"
        btnAction="/classes"
        btnLabel="Find Out More"
      >
        <ul>
          <li>Dynamic one-to-one sessions.</li>
          <li>Pilates Sculpt&reg; class.</li>
          <li>Community fitness in Tower Hamlets.</li>
          <li>Fitness journalism.</li>
        </ul>
      </PromoVideo>

      <ImageText
        cssClass="image-text"
        heading="Laura’s qualifications include"
        imageSrc="about_quals.jpg"
        imageAlt="Personal Training photograph"
      >
        <ul>
          <li>
            CYQ/NVQ Level 3 Personal Training (including Advanced Anatomy and
            Physiology; Client Lifestyle Assessment; Advanced Gym Instruction)
          </li>
          <li>CYQ/NVQ Level 3 Nutrition and Weight Management</li>
          <li>CYQ/NVQ Level 3 Pre-and-Post Natal Fitness</li>
          <li>
            CYQ/NVQ Level 3 Sports Conditioning (designing of
            programmes/exercise prescription for sportspeople)
          </li>
          <li>
            Pilates Institute Level 3 Pilates Matwork/Pilates Anatomy &
            Physiology
          </li>
          <li>NCFE CACHE Level 2 Certificate in Mental Health Awareness</li>
        </ul>
      </ImageText>
    </Layout>
  )
}

export default About
