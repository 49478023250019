import React from "react"
import classnames from "classnames"
import Image from "../components/Image"

const ImageText = props => (
  <div className={classnames("image-text ", props.className)}>
    <div className="container">
      <div className="image-text__image">
        <Image filename={props.imageSrc} alt={props.imageAlt} />
      </div>
      <div className="image-text__text">
        <h2 className="heading">{props.heading}</h2>
        <div className="image-text__text-main">{props.children}</div>
      </div>
    </div>
  </div>
)

export default ImageText
